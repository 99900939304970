import { Box, Button, styled, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";

import heroImg from "../media/hero_illustration.png";
import CustomButton from "./CustomButton";
import logoImg from "../media/logo.png";

const HomePage = () => {
  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(5),
    paddingLeft: 30,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      paddingLeft: 0,
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "44px",
    color: "000336",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  }));

  const Logo = styled('img')(({ theme }) => ({
    maxWidth: "150px",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "10px", // Move the logo down for smaller screens
      maxWidth: "120px", // Adjust the logo size for smaller screens
    },
  }));



  return <Box sx={{backgroundColor: "#E6F0FF", minHeight: "100vh"}}>
  <Box paddingY ={2} textAlign={'center'}>
    <Logo src={logoImg} alt="Logo" />
  </Box>
      <Container >
        <CustomBox>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: { xs: 'center',sm: 'center', md: 'self-start' }, flexDirection: 'column', mt: {xs: 0, sm: 8}}}>
          <Typography 
            variant = "body2" 
            sx={{
              fontSize: "22px", 
              color: "#01BF71", 
              fontWeight: "700", 
            }}>
              Welcome to DockDevelopers
            </Typography>
            <Title variant="h1">
              This domain has been parked by DockDevelopers!!
            </Title>
            <Typography
              variant="body2"
              sx={{
                fontsize: "18px", 
                color: "#687690", 
                mb: 2,
              }}
            >
              We're here to help you every step of the way, from choosing a domain name to parking it to developing your website or app.
            </Typography>
            <Box>
              <CustomButton backgroundColor="#01BF71" color="#ffff" buttonText="More About Us" heroBtn={true} />
            </Box>
          </Box>

          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center'}} marginY={2}>
            <Box>
            <img 
              src={heroImg}
              alt="heroImg"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
            </Box>
          </Box>
        </CustomBox>
      </Container>
    </Box>;
};

export default HomePage;
