import { initializeFirebase } from "./firebase";
import HomePage from "./Components/Hompage";

function App() {
  initializeFirebase();
  return <>
  <HomePage />
  </>;
}

export default App;
