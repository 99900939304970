// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAoIt2JnY9G8umELCndmx-mi03V-eE5Ktw",
  authDomain: "dd-parked.firebaseapp.com",
  projectId: "dd-parked",
  storageBucket: "dd-parked.appspot.com",
  messagingSenderId: "1034712030172",
  appId: "1:1034712030172:web:38103336c60ae9623c4d19"
};

// Initialize Firebase
export const initializeFirebase = () => {
    initializeApp(firebaseConfig);
};